import TPMicro from './micro'
import TPMicroReview from './micro-review'
import TPMini from './mini'

type TrustpilotBoxProps = {
  boxStyle: 'micro' | 'microReview' | 'mini'
  darkBg?: boolean
}

export interface TrustData {
  label: string
  value: number
  count: number
  url: string
}

export const TrustpilotBox = ({ boxStyle, darkBg = false }: TrustpilotBoxProps) => {
  return (
    <>
      {boxStyle === 'micro' && <TPMicro trustData={trustpilotData} darkBg={darkBg} />}

      {boxStyle === 'microReview' && <TPMicroReview trustData={trustpilotData} />}

      {boxStyle === 'mini' && <TPMini trustData={trustpilotData} />}
    </>
  )
}

export default TrustpilotBox

export const trustpilotData = {
  label: 'Excellent',
  value: 4.9,
  count: 70575,
  url: 'https://uk.trustpilot.com/review/selph.co.uk',
}

// TO-DO: We can use this URL:
//   "https://uk.trustpilot.com/_next/data/businessunitprofile-consumersite-2.219.0/review/selph.co.uk.json?businessUnit=selph.co.uk&languages=all&utm_medium=trustbox&utm_source=MicroCombo"
// to get the Trustpilot data
